import React from 'react';
import { Stack, ThemeProvider, createTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import EmailForm from './emailForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { createBrowserHistory } from "history";


// TODO: Make this page more beautiful

const theme = createTheme({
  typography: {
    h3: {
      fontFamily: 'DS, Roboto'
    },
    h4: {
      fontFamily: 'DS, Jersey M54 V1, Roboto'
    }
  },
});

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();

var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
};

var appInsights = new ApplicationInsights({
  config: {
      //instrumentationKey: '17da5b06-8ebe-46bd-9781-483bd7f65da3',
      connectionString: 'InstrumentationKey=17da5b06-8ebe-46bd-9781-483bd7f65da3;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/',
      extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig
      }
  }
  });
appInsights.loadAppInsights();

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey="6LcKON0nAAAAAHl_bz4OMdpwFbMH7xR0wbHguQai">
      <CssBaseline />      
      <Box sx={{ height:120, width: '100%', m: 0, p: 1, backgroundColor: 'common.black' }}>
        <Stack direction="row" justifyContent="center" alignItems="center" >
        <Box
          component="img"
          sx={{
            height: 90,
            width: 100,
            maxHeight: { xs: 90, md: 90 },
            maxWidth: { xs: 100, md: 100 },
          }}
          alt="Cobra Chickens Hockey logo"
          src="/images/chicken.svg"
        />
        <Typography variant="h3" color="common.white">
          Cobra Chickens Hockey Co-op
        </Typography>   
        </Stack>
      </Box>
      <Box sx={{ height:300, width: '100%', m: 0, backgroundImage: `url(/images/banner.jpg)`, backgroundSize: 'cover' }}/>
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}></Grid>
          <Grid item xs={5}>
            <Typography variant="h4" sx={{mb:2}}>
              Who are we?
            </Typography>
            <Typography variant="body1">
              We're a free-range elite recreational spring hockey team in Vancouver, British Columbia. We share a love of hockey, the Canada Goose, and <Link href="https://www.overgrowthrenaissance.com/">OGR-Scintilla</Link>.
              In spring 2024, our 2012 team plans to have four practice skates and participate in two tournaments.
            </Typography>  
          </Grid>
          <Grid item xs={7}>
            <Box
                component="img"
                sx={{ height: '100%', width: '100%' }}
                alt="Goal celebration"
                src="/images/who_are_we.jpg"
            />
          </Grid>
          <Grid item xs={7}>
            <Box
                component="img"
                sx={{ height: '100%', width: '100%' }}
                alt="Ready for anything"
                src="/images/i_want_to_be_a_cobra_chicken.jpg"
            />            
          </Grid>               
          <Grid item xs={5}>
              <Typography variant="h4" sx={{mb:2}}>
              I want to be a Cobra Chicken!
            </Typography>
            <Typography variant="body1">
            Deep down, we're all Cobra Chickens. But we get it. You want to fly with us (or walk across the road, holding up traffic). Our 2012 team is full, but let us know if you'd like to use the Cobra Chickens name for a different age group.</Typography>  
          </Grid>
          <Grid item xs={11}>
            <EmailForm/>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>

      <Box sx={{ height:50, width: '100%', m: 0, p: 1, backgroundColor: 'common.black' }} >
        <Typography variant="body" color="common.white">
        © Cobra Chickens Hockey Co-op. Winging it since 2023.
        </Typography>        
      </Box> 
      </GoogleReCaptchaProvider>  
      </ThemeProvider>  
    </React.Fragment>
  );
}

export default withAITracking(reactPlugin, App);

import React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Stack, Snackbar, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// TODO: make the form more beautiful

const validationSchema = yup.object({
    name: yup.string('Enter your name').required('Name is required'),
    email: yup.string('Enter your email').email('Please enter a valid email').required('Email is required'),
    message: yup.string('Send a message')
  });


function EmailForm() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [ open, setOpen ] = React.useState(false);
    const [ message, setMessage ] = React.useState("");
    const [ loading, setLoading ] = React.useState(false);
    const [ alertSeverity, setAlertSeverity ] = React.useState('success');

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            submitForm(values)
        },
    });


    const snackBarMessage = (message, severity) => {
        setAlertSeverity(severity);
        setMessage(message);
        setOpen(true);
    };

    const submitForm = React.useCallback(async (values) => {
        try {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            // Execute recaptcha and get token
            const token = await executeRecaptcha('formSubmit');
            values.token = token

            setLoading(true);
            const response = await axios.post('/api/SendMessage', values);
            console.log(response);
            snackBarMessage(response.data, response.status === 200 ? 'success' : 'error')
            setLoading(false);
            formik.resetForm();
        } catch(err) {
            console.log(err)
            snackBarMessage(err.message, 'error')
            setLoading(false);
        }

    }, [executeRecaptcha, formik]);    

    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };
    

    return (
    <React.Fragment>
        <form onSubmit={formik.handleSubmit} data-id="contactForm" id="contactForm">
            <Stack spacing={2}>
                <Typography variant="h4" sx={{mb:2}}>
                    Contact us
                </Typography>                
                <TextField id="name" name="name" label="Name" variant="outlined"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField id="email" name="email" label="Email" variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />            
                <TextField id="message" name="message" label="Message" variant="outlined" multiline minRows={3}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                />
                <LoadingButton color="primary" variant="contained" loading={loading} type="submit" data-id="formSubmit" id="formSubmit">
                    Submit
                </LoadingButton>   
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>        
            </Stack>
        </form>
    </React.Fragment>
  );
}

export default EmailForm;
